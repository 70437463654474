import { useFeeDiscountList } from "@gocardless/api/dashboard/fee-discount";
import {
  FeeDiscountResource,
  DiscountType,
} from "@gocardless/api/dashboard/types";
import { useEffect, useState } from "react";
import { useOrganisation } from "src/queries/organisation";

interface UseActivationDiscount {
  activationDiscount?: FeeDiscountResource;
  firstPayoutDiscount?: FeeDiscountResource;
  canExtendDiscount?: boolean;
  remainingDays?: number;
  digits?: string[];
  discountLength?: number;
}

export const DISCOUNT_EXTENSION_LENGTH = 30;
const MAX_ORGANISATION_AGE_ELIGIBLE_FOR_DISCOUNT_EXTENSION = 30;

/*
 * This returns the number of free transaction days
 * remaining for the organisation with one of the recognised
 * activation discounts
 */
export const useActivationDiscount = (): UseActivationDiscount => {
  const { data: discountData } = useFeeDiscountList();
  const organisation = useOrganisation();

  const [activationDiscount, setActivationDiscount] = useState<
    FeeDiscountResource | undefined
  >();

  useEffect(() => {
    // The order of the array matters
    const discountTypes = [
      DiscountType.CustomerSupport30_DaysDiscount,
      DiscountType.PreActiveDiscount90_Days,
      DiscountType.CustomisableActivationDiscount,
      DiscountType.ActivationDiscount90_Days,
      DiscountType.The10DaysFreeFeeActivationUploadDocument,
      DiscountType.The15DaysFreeFeeActivationCompleteSetup,
      DiscountType.The15DaysFreeFeeActivationCompleteSetupExtension,
      DiscountType.The30DaysFreeFeeActivationAddFirstCustomer,
      DiscountType.The30DaysFreeFeeActivationCollectFirstPayment,
      DiscountType.The30DaysFreeFeeActivationCompleteSetup,
      DiscountType.The30DaysFreeFeeActivationCreateAccount,
    ];

    for (const discountType of discountTypes) {
      const discount = discountData?.fee_discounts?.find(
        (d) => d?.discount_type === discountType
      );

      if (discount) {
        setActivationDiscount(discount);
        break;
      }
    }
  }, [setActivationDiscount, discountData]);

  const firstPayoutDiscount = discountData?.fee_discounts?.find(
    (discount) =>
      discount?.discount_type === DiscountType.FirstPayoutDiscount30_DaysTgExp
  );

  if (
    !activationDiscount ||
    !activationDiscount.start_date ||
    !activationDiscount.end_date
  ) {
    return { activationDiscount };
  }

  const activationStartDate = Date.parse(activationDiscount.start_date);
  const activationEndDate = Date.parse(activationDiscount.end_date);

  const endDate = Date.parse(
    firstPayoutDiscount?.end_date || activationDiscount.end_date
  );

  if (Date.now() > endDate) {
    return { activationDiscount, firstPayoutDiscount };
  }

  const remainingDays = Math.ceil((endDate - Date.now()) / 86400000); // ms in one day
  const digits = remainingDays.toString().padStart(2, "0").split("");
  const organisationCreatedAtTimestamp = organisation?.created_at
    ? Math.floor(new Date(organisation?.created_at).getTime())
    : Date.now();
  const organisationCreationDays = Math.ceil(
    (Date.now() - organisationCreatedAtTimestamp) / 86400000
  ); // ms in one day
  const canExtendDiscount =
    !firstPayoutDiscount &&
    organisationCreationDays <=
      MAX_ORGANISATION_AGE_ELIGIBLE_FOR_DISCOUNT_EXTENSION;
  const discountLength = Math.ceil(
    (activationEndDate - activationStartDate) / 86400000
  ); // ms in one day

  return {
    activationDiscount,
    firstPayoutDiscount,
    canExtendDiscount,
    remainingDays,
    digits,
    discountLength,
  };
};
